import { useCallback } from "react";

const WebDesignCard = () => {
  const onLinkButtonClick = useCallback(() => {
    window.open("https://www.behance.net/waleedabid1");
  }, []);

  return (
    <div className="self-stretch flex flex-row items-end justify-center gap-[49px] max-w-full text-left text-21xl text-white font-exo-2 mq800:gap-[24px] mq1325:flex-wrap">
      <img
        className="h-[376px] w-[317px] relative rounded-6xl overflow-hidden shrink-0 object-cover min-h-[376px] mq1325:flex-1"
        loading="lazy"
        alt=""
        src="/picture-1@2x.png"
      />
      <div className="flex-1 flex flex-col items-start justify-start gap-[51px] min-w-[557px] max-w-full mq1125:min-w-full mq450:gap-[25px]">
        <h2 className="m-0 relative text-inherit font-semibold font-inherit mq800:text-13xl mq450:text-5xl">
          Web Design
        </h2>
        <div className="self-stretch flex flex-col items-start justify-start gap-[29px] text-6xl">
          <div className="self-stretch relative mq450:text-xl">
            <p className="m-0">
              Web Designer with a focus on crafting visually appealing and
              user-friendly websites. Skilled in utilizing industry-standard
              design tools for a seamless workflow, including:
            </p>
            <ul className="m-0 font-inherit text-inherit pl-[33px]">
              <li className="mb-0">
                Content Management System (CMS): WordPress (expertise in
                building and customizing websites)
              </li>
              <li>{`UI/UX Design Tools: Figma & Adobe XD (proficiency in designing user interfaces and user experiences)`}</li>
            </ul>
          </div>
          <button
            className="cursor-pointer [border:none] pt-[7.2px] px-[20.5px] pb-[6.3px] bg-[transparent] [backdrop-filter:blur(15px)] rounded-xl [background:linear-gradient(90deg,_rgba(255,_255,_255,_0.4),_rgba(255,_255,_255,_0.4))] flex flex-row items-start justify-start"
            onClick={onLinkButtonClick}
          >
            <div className="h-[39px] w-[68px] relative [backdrop-filter:blur(15px)] rounded-xl [background:linear-gradient(90deg,_rgba(255,_255,_255,_0.4),_rgba(255,_255,_255,_0.4))] hidden" />
            <img
              className="h-[25.5px] w-[27px] relative z-[1]"
              alt=""
              src="/vector-3.svg"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebDesignCard;
