import { useCallback } from "react";

const ComtactMe = () => {
  const onLinkedinContainerClick = useCallback(() => {
    window.open("https://www.linkedin.com/in/raja-waleed-abid-88088a217");
  }, []);

  const onGithubContainerClick = useCallback(() => {
    window.open("https://github.com/rajawaleed10");
  }, []);

  const onInstagramContainerClick = useCallback(() => {
    window.open("https://www.instagram.com/waleed.designs_/");
  }, []);

  const onContactMeButtonClick = useCallback(() => {
    window.open("https://wa.me/+923165182294");
  }, []);

  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full text-left text-21xl text-white font-exo-2">
      <div
        className="flex-1 bg-midnightblue-100 overflow-hidden flex flex-col items-start justify-start pt-[76px] px-[149px] pb-[38px] box-border gap-[39px] max-w-full mq800:gap-[19px] mq800:pt-[49px] mq800:px-[74px] mq800:pb-[25px] mq800:box-border mq450:pl-5 mq450:pr-5 mq450:box-border"
        data-scroll-to="contactContainer"
      >
        <div className="self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-1.5">
          <h2 className="m-0 relative text-inherit font-semibold font-inherit mq800:text-13xl mq450:text-5xl">
            <span>{`Contact `}</span>
            <span className="text-darkorange">Me</span>
          </h2>
        </div>
        <img
          className="w-[267px] h-[257px] relative rounded-[50%] object-cover"
          loading="lazy"
          alt=""
          src="/my-pic@2x.png"
        />
        <div className="flex flex-col items-start justify-start gap-[27.7px] max-w-full text-16xl">
          <h3 className="m-0 self-stretch flex-1 relative text-inherit font-semibold font-inherit whitespace-nowrap mq800:text-9xl mq450:text-2xl">
            waleed.abid.10@outlook.com
          </h3>
          <div className="self-stretch flex-1 relative font-semibold whitespace-nowrap mq800:text-9xl mq450:text-2xl">
            +92316-5182294
          </div>
          <div className="flex flex-row items-start justify-start gap-[24px]">
            <div
              className="h-[30px] w-[30px] relative cursor-pointer"
              onClick={onLinkedinContainerClick}
            >
              <div className="absolute top-[calc(50%_-_15px)] left-[calc(50%_-_15px)] rounded-[50%] bg-darkorange w-full h-full" />
              <img
                className="absolute top-[calc(50%_-_7px)] left-[calc(50%_-_7px)] w-[13.1px] h-[13.1px] z-[1]"
                loading="lazy"
                alt=""
                src="/vector-6.svg"
              />
            </div>
            <div
              className="h-[30px] w-[30px] relative cursor-pointer"
              onClick={onGithubContainerClick}
            >
              <div className="absolute top-[calc(50%_-_15px)] left-[calc(50%_-_15px)] rounded-[50%] bg-darkorange w-full h-full" />
              <img
                className="absolute top-[calc(50%_-_7px)] left-[calc(50%_-_7px)] w-[14.5px] h-[14.2px] z-[1]"
                loading="lazy"
                alt=""
                src="/vector-11.svg"
              />
            </div>
            <div
              className="h-[30px] w-[30px] relative cursor-pointer"
              onClick={onInstagramContainerClick}
            >
              <div className="absolute top-[calc(50%_-_15px)] left-[calc(50%_-_15px)] rounded-[50%] bg-darkorange w-full h-full" />
              <img
                className="absolute top-[calc(50%_-_7px)] left-[calc(50%_-_7px)] w-[13.1px] h-[13.1px] z-[1]"
                loading="lazy"
                alt=""
                src="/vector-8.svg"
              />
            </div>
          </div>
          <button
            className="cursor-pointer [border:none] py-[11px] px-[53px] bg-darkorange rounded-mini flex flex-row items-start justify-start whitespace-nowrap hover:bg-chocolate"
            onClick={onContactMeButtonClick}
          >
            <div className="h-11 w-[202px] relative rounded-mini bg-darkorange hidden" />
            <div className="relative text-lg font-semibold font-exo-2 text-white text-left inline-block min-w-[96px] z-[1]">
              Contact Me
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default ComtactMe;
