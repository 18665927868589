import { useCallback } from "react";

const Footer = () => {
  const onLinkedinClick = useCallback(() => {
    window.open("https://www.linkedin.com/in/raja-waleed-abid-88088a217");
  }, []);

  const onInstagramContainerClick = useCallback(() => {
    window.open("https://www.instagram.com/waleed.designs_/");
  }, []);

  const onGithubContainerClick = useCallback(() => {
    window.open("https://github.com/rajawaleed10");
  }, []);

  const onAboutMeText2Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='aboutContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onSkillsText1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='skillsContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onPortfolioText1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='portfolio']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onWorkText1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='workContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onContactText1Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <section className="self-stretch bg-midnightblue-200 flex flex-col items-center justify-start pt-[86px] pb-[30px] pr-5 pl-[25px] box-border gap-[41px] max-w-full text-center text-6xl text-white font-exo-2 mq800:gap-[20px] mq800:pt-14 mq800:pb-5 mq800:box-border">
      <div className="w-[501px] flex flex-row items-start justify-start py-0 pr-[66px] pl-[61px] box-border max-w-full mq800:pl-[30px] mq800:pr-[33px] mq800:box-border">
        <div className="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq450:flex-wrap">
          <button
            className="cursor-pointer [border:none] p-0 bg-[transparent] w-[78px] flex flex-row items-start justify-start"
            onClick={onLinkedinClick}
          >
            <div className="h-[73px] flex-1 relative">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkorange w-full h-full" />
              <img
                className="absolute top-[19.5px] left-[22px] w-[34.1px] h-[31.9px] z-[1]"
                alt=""
                src="/vector-9.svg"
              />
            </div>
          </button>
          <div
            className="w-[78px] flex flex-row items-start justify-start cursor-pointer"
            onClick={onInstagramContainerClick}
          >
            <div className="h-[73px] flex-1 relative">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkorange w-full h-full" />
              <img
                className="absolute top-[19.5px] left-[22px] w-[34.1px] h-[31.9px] z-[1]"
                loading="lazy"
                alt=""
                src="/vector-10.svg"
              />
            </div>
          </div>
          <div
            className="w-[78px] flex flex-row items-start justify-start cursor-pointer"
            onClick={onGithubContainerClick}
          >
            <div className="h-[73px] flex-1 relative">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkorange w-full h-full" />
              <img
                className="absolute top-[19.5px] left-[20px] w-[37.8px] h-[34.5px] z-[1]"
                loading="lazy"
                alt=""
                src="/vector-111.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[501px] flex flex-row items-start justify-center py-0 pr-[5px] pl-0 box-border max-w-full">
        <div className="flex flex-col items-start justify-start gap-[18px]">
          <div
            className="relative font-semibold inline-block min-w-[112px] cursor-pointer mq450:text-xl"
            onClick={onAboutMeText2Click}
          >
            About Me
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-6">
            <div
              className="relative font-semibold inline-block min-w-[64px] cursor-pointer mq450:text-xl"
              onClick={onSkillsText1Click}
            >
              Skills
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-1">
            <div
              className="relative font-semibold inline-block min-w-[104px] cursor-pointer mq450:text-xl"
              onClick={onPortfolioText1Click}
            >
              Portfolio
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 pr-6 pl-[24.5px]">
            <div
              className="relative font-semibold inline-block min-w-[63px] cursor-pointer mq450:text-xl"
              onClick={onWorkText1Click}
            >
              Work
            </div>
          </div>
          <div className="flex flex-row items-start justify-start py-0 pr-2.5 pl-[10.5px]">
            <div
              className="relative font-semibold inline-block min-w-[91px] cursor-pointer mq450:text-xl"
              onClick={onContactText1Click}
            >
              Contact
            </div>
          </div>
        </div>
      </div>
      <b className="relative text-xl inline-block whitespace-pre-wrap max-w-full mq450:text-base">{`Copyright 2024  Designed And Coded By Waleed Abid `}</b>
    </section>
  );
};

export default Footer;
