import { useEffect } from "react";

const About = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <section
      className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] max-w-full text-left text-21xl text-white font-exo-2"
      data-animate-on-scroll
    >
      <div
        className="flex-1 bg-midnightblue-200 flex flex-col items-start justify-start pt-[66px] pb-[182px] pr-[67.6px] pl-[150px] box-border gap-[129px] max-w-full mq800:gap-[64px] mq800:pl-[75px] mq800:pr-[34px] mq800:box-border mq1125:pt-[43px] mq1125:pb-[118px] mq1125:box-border mq450:gap-[32px] mq450:pl-5 mq450:pt-7 mq450:pb-[77px] mq450:box-border"
        data-scroll-to="aboutContainer"
      >
        <div className="w-[1440px] h-[800px] relative bg-midnightblue-200 hidden max-w-full" />
        <div className="w-[1077px] flex flex-row items-start justify-center max-w-full">
          <h2 className="m-0 relative text-inherit font-semibold font-inherit z-[1] mq800:text-13xl mq450:text-5xl">
            <span>{`About `}</span>
            <span className="text-darkorange">Me</span>
          </h2>
        </div>
        <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[20px] text-11xl mq1325:flex-wrap mq1325:justify-center">
          <img
            className="w-[347px] relative rounded-mini max-h-full object-cover max-w-full z-[1] mq1325:flex-1"
            loading="lazy"
            alt=""
            src="/about-img@2x.png"
          />
          <div className="w-[736.4px] flex flex-col items-start justify-start gap-[39px] min-w-[736.4000000000001px] max-w-full mq800:gap-[19px] mq1125:min-w-full mq1325:flex-1">
            <div className="relative font-semibold z-[1] mq800:text-5xl mq450:text-lg">
              <p className="m-0">{`Frontend Developer &`}</p>
              <p className="m-0">Graphic Designer</p>
            </div>
            <div className="self-stretch relative text-6xl leading-[24px] font-inter z-[1] mq450:text-xl mq450:leading-[19px]">
              I am Raja Waleed Abid, a versatile Graphic Designer with a passion
              for front-end development, including expertise in React.js, React
              Native, Next.js, Vue, and Gatsby. Currently based in Islamabad,
              Pakistan, I have been honing my design and development skills at
              Hendry Group since 2023. With a Bachelor of Science in Computer
              Science in progress from the Shaheed Zulfikar Ali Bhutto Institute
              of Science and Technology, I bring a broad technical expertise to
              the table. My experience spans multiple programming languages and
              development frameworks, allowing me to create scalable,
              maintainable, and efficient software architectures.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
