import { useEffect } from "react";
import NavBar from "../components/nav-bar";
import Home from "../components/home";
import About from "../components/about";
import WebDevCard from "../components/web-dev-card";
import WebDesignCard from "../components/web-design-card";
import GraphicDesignCard from "../components/graphic-design-card";
import Projects from "../components/projects";
import Work from "../components/work";
import ComtactMe from "../components/comtact-me";
import Footer from "../components/footer";

const PortfolioWebsite = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div className="w-full relative bg-darkorange flex flex-col items-start justify-start leading-[normal] tracking-[normal]">
      <NavBar />
      <Home />
      <About />
      <section
        className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] max-w-full text-left text-21xl text-white font-exo-2"
        data-animate-on-scroll
      >
        <div
          className="flex-1 bg-midnightblue-100 flex flex-col items-start justify-start pt-[76px] pb-[178px] pr-[68px] pl-[136px] box-border gap-[111px] max-w-full mq800:gap-[55px] mq800:pl-[68px] mq800:pr-[34px] mq800:box-border mq1125:pt-[49px] mq1125:pb-[116px] mq1125:box-border mq450:gap-[28px] mq450:pl-5 mq450:pt-8 mq450:pb-[75px] mq450:box-border"
          data-scroll-to="skillsContainer"
        >
          <div className="w-[1440px] h-[800px] relative bg-midnightblue-100 hidden max-w-full" />
          <div className="w-[1167px] flex flex-row items-start justify-center max-w-full">
            <h2 className="m-0 relative text-inherit font-semibold font-inherit z-[1] mq800:text-13xl mq450:text-5xl">
              <span>{`My `}</span>
              <span className="text-darkorange">TEC-STACK</span>
            </h2>
          </div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full z-[1]"
            loading="lazy"
            alt=""
            src="/tec-stack.svg"
          />
        </div>
      </section>
      <section
        className="self-stretch bg-midnightblue-200 overflow-hidden flex flex-col items-start justify-start py-[76px] pr-[68px] pl-[149px] box-border gap-[86px] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] max-w-full text-left text-21xl text-white font-exo-2 mq800:gap-[43px] mq800:py-8 mq800:pr-[34px] mq800:pl-[74px] mq800:box-border mq450:gap-[21px] mq450:pl-5 mq450:pt-[21px] mq450:pb-[21px] mq450:box-border mq1325:pt-[49px] mq1325:pb-[49px] mq1325:box-border"
        data-scroll-to="portfolio"
        data-animate-on-scroll
      >
        <div className="w-[1141px] flex flex-row items-start justify-center max-w-full">
          <h2 className="m-0 relative text-inherit font-semibold font-inherit mq800:text-13xl mq450:text-5xl">
            <span>{`My `}</span>
            <span className="text-darkorange">Portfolio</span>
          </h2>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[74px] max-w-full mq800:gap-[37px] mq450:gap-[18px]">
          <WebDevCard />
          <WebDesignCard />
          <GraphicDesignCard />
        </div>
      </section>
      <Projects />
      <Work />
      <ComtactMe />
      <Footer />
    </div>
  );
};

export default PortfolioWebsite;
