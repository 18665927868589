import { useCallback, useEffect } from "react";

const Drawer = ({ onClose }) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onAboutMeClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='aboutContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    onClose && onClose();
  }, []);

  const onSkillsClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='skillsContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    onClose && onClose();
  }, []);

  const onPortfolio1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='portfolio']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    onClose && onClose();
  }, []);

  const onWorkClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='workContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    onClose && onClose();
  }, []);

  const onContactClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    onClose && onClose();
  }, []);

  return (
    <div
      className="w-full h-[463px] [backdrop-filter:blur(15px)] [background:linear-gradient(93.66deg,_rgba(255,_255,_255,_0.4),_rgba(255,_255,_255,_0.4))] overflow-hidden flex flex-col items-center justify-start py-[111px] px-5 box-border gap-[14px] [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-top] opacity-[0] leading-[normal] tracking-[normal] max-h-[90%]"
      data-animate-on-scroll
    >
      <button
        className="cursor-pointer [border:none] py-[9px] px-[7px] bg-darkorange rounded-xl flex flex-row items-start justify-start whitespace-nowrap hover:bg-chocolate"
        onClick={onAboutMeClick}
      >
        <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
        <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[72px] z-[1]">
          About Me
        </div>
      </button>
      <button
        className="cursor-pointer [border:none] py-[9px] pr-[22px] pl-[23px] bg-darkorange rounded-xl flex flex-row items-start justify-start hover:bg-chocolate"
        onClick={onSkillsClick}
      >
        <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
        <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[41px] z-[1]">
          Skills
        </div>
      </button>
      <button
        className="cursor-pointer [border:none] py-[9px] px-2.5 bg-darkorange rounded-xl flex flex-row items-start justify-start hover:bg-chocolate"
        onClick={onPortfolio1Click}
      >
        <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
        <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[66px] z-[1]">
          Portfolio
        </div>
      </button>
      <button
        className="cursor-pointer [border:none] py-[9px] px-[23px] bg-darkorange rounded-xl flex flex-row items-start justify-start hover:bg-chocolate"
        onClick={onWorkClick}
      >
        <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
        <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[40px] z-[1]">
          Work
        </div>
      </button>
      <button
        className="cursor-pointer [border:none] py-[9px] px-3.5 bg-darkorange rounded-xl flex flex-row items-start justify-start hover:bg-chocolate"
        onClick={onContactClick}
      >
        <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
        <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[58px] z-[1]">
          Contact
        </div>
      </button>
    </div>
  );
};

export default Drawer;
