import { useState, useCallback } from "react";
import Drawer from "./drawer";
import PortalDrawer from "./portal-drawer";

const NavBar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const onAboutMeClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='aboutContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onSkillsClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='skillsContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onPortfolioClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='portfolio']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onWorkClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='workContainer']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onContactClick = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <header className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full">
        <div className="flex-1 bg-midnightblue-200 flex flex-row items-start justify-between py-0 pr-[39px] pl-[38px] box-border max-w-full gap-[20px]">
          <div className="self-stretch w-[1440px] relative bg-midnightblue-200 hidden max-w-full" />
          <div className="flex flex-row items-end justify-start gap-[12px]">
            <div className="flex flex-col items-start justify-end pt-0 px-0 pb-[27px]">
              <div
                className="w-[41px] h-[29px] relative rounded-8xs bg-white cursor-pointer z-[1]"
                onClick={openDrawer}
              >
                <div className="absolute top-[15px] left-[5px] box-border w-[25px] h-0.5 border-t-[2px] border-solid border-black" />
                <div className="absolute top-[8px] left-[5px] box-border w-[33px] h-0.5 border-t-[2px] border-solid border-black" />
                <div className="absolute top-[22px] left-[5px] box-border w-[19px] h-0.5 border-t-[2px] border-solid border-black" />
              </div>
            </div>
            <img
              className="h-[85px] w-[103px] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/logo@2x.png"
            />
          </div>
          <div className="w-[486px] flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border max-w-full mq800:w-0">
            <div className="self-stretch flex flex-row items-start justify-start gap-[14px] mq800:hidden">
              <button
                className="cursor-pointer [border:none] py-[9px] px-[7px] bg-darkorange rounded-xl flex flex-row items-start justify-start whitespace-nowrap z-[1] hover:bg-chocolate"
                onClick={onAboutMeClick}
              >
                <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
                <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[72px] z-[1]">
                  About Me
                </div>
              </button>
              <button
                className="cursor-pointer [border:none] py-[9px] pr-[22px] pl-[23px] bg-darkorange rounded-xl flex flex-row items-start justify-start z-[1] hover:bg-chocolate"
                onClick={onSkillsClick}
              >
                <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
                <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[41px] z-[1]">
                  Skills
                </div>
              </button>
              <button
                className="cursor-pointer [border:none] py-[9px] px-2.5 bg-darkorange rounded-xl flex flex-row items-start justify-start z-[1] hover:bg-chocolate"
                onClick={onPortfolioClick}
              >
                <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
                <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[66px] z-[1]">
                  Portfolio
                </div>
              </button>
              <button
                className="cursor-pointer [border:none] py-[9px] px-[23px] bg-darkorange rounded-xl flex flex-row items-start justify-start z-[1] hover:bg-chocolate"
                onClick={onWorkClick}
              >
                <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
                <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[40px] z-[1]">
                  Work
                </div>
              </button>
              <button
                className="cursor-pointer [border:none] py-[9px] px-3.5 bg-darkorange rounded-xl flex flex-row items-start justify-start z-[1] hover:bg-chocolate"
                onClick={onContactClick}
              >
                <div className="h-[37px] w-[86px] relative rounded-xl bg-darkorange hidden" />
                <div className="relative text-base font-semibold font-exo-2 text-white text-left inline-block min-w-[58px] z-[1]">
                  Contact
                </div>
              </button>
            </div>
          </div>
        </div>
      </header>
      {isDrawerOpen && (
        <PortalDrawer placement="Top" onOutsideClick={closeDrawer}>
          <Drawer onClose={closeDrawer} />
        </PortalDrawer>
      )}
    </>
  );
};

export default NavBar;
