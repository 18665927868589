import { useEffect } from "react";

const Projects = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full text-left text-21xl text-white font-exo-2">
      <div
        className="flex-1 bg-midnightblue-100 overflow-hidden flex flex-col items-center justify-start pt-[76px] px-5 pb-[114px] box-border gap-[47px] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] max-w-full mq800:gap-[23px] mq800:pt-5 mq800:pb-5 mq800:box-border mq1125:pt-[21px] mq1125:pb-[31px] mq1125:box-border mq1325:pt-8 mq1325:pb-12 mq1325:box-border"
        data-animate-on-scroll
      >
        <div className="w-[1144px] flex flex-row items-start justify-center py-0 pr-0 pl-px box-border max-w-full">
          <h2 className="m-0 relative text-inherit font-semibold font-inherit mq800:text-13xl mq450:text-5xl">
            <span>{`Here Are `}</span>
            <span className="text-darkorange">Some Of My Projects</span>
          </h2>
        </div>
        <div className="w-[1143px] flex flex-col items-start justify-start gap-[54px] max-w-full mq800:gap-[27px]">
          <div className="self-stretch grid flex-row items-start justify-start gap-[54px] max-w-full grid-cols-[repeat(3,_minmax(259px,_1fr))] mq800:gap-[27px] mq800:grid-cols-[minmax(259px,_1fr)] mq1125:justify-center mq1125:grid-cols-[repeat(2,_minmax(259px,_448px))]">
            <img
              className="relative rounded-mini max-w-full overflow-hidden max-h-full object-cover min-h-[486px] hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1] mq800:w-full"
              loading="lazy"
              alt=""
              src="/project-1@2x.png"
            />
            <img
              className="relative rounded-mini max-w-full overflow-hidden max-h-full object-cover min-h-[486px] hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1] mq800:w-full"
              loading="lazy"
              alt=""
              src="/project-2@2x.png"
            />
            <img
              className="relative rounded-mini max-w-full overflow-hidden max-h-full object-cover min-h-[486px] hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1] mq800:w-full"
              loading="lazy"
              alt=""
              src="/project-3@2x.png"
            />
          </div>
          <img
            className="self-stretch flex-1 relative rounded-mini max-w-full overflow-hidden max-h-full object-cover hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1]"
            loading="lazy"
            alt=""
            src="/project-4@2x.png"
          />
          <img
            className="self-stretch flex-1 relative rounded-mini max-w-full overflow-hidden max-h-full object-cover hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1]"
            loading="lazy"
            alt=""
            src="/project-5@2x.png"
          />
          <img
            className="self-stretch flex-1 relative rounded-mini max-w-full overflow-hidden max-h-full object-cover hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1]"
            loading="lazy"
            alt=""
            src="/project-6@2x.png"
          />
          <img
            className="self-stretch flex-1 relative rounded-mini max-w-full overflow-hidden max-h-full object-cover hover:animate-[1s_ease_0s_1_normal_none_scale-up] hover:opacity-[1]"
            loading="lazy"
            alt=""
            src="/project-7@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

export default Projects;
