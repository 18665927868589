import { useEffect } from "react";
import ExperienceTab from "./experience-tab";

const Work = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full text-left text-21xl text-white font-exo-2">
      <div
        className="flex-1 bg-midnightblue-200 overflow-hidden flex flex-col items-start justify-start pt-[76px] pb-[54px] pr-[68px] pl-[149px] box-border gap-[121px] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0] max-w-full mq800:gap-[60px] mq800:pt-[21px] mq800:pb-5 mq800:pr-[34px] mq800:pl-[74px] mq800:box-border mq1125:pt-8 mq1125:pb-[23px] mq1125:box-border mq450:gap-[30px] mq450:pl-5 mq450:box-border mq1325:pt-[49px] mq1325:pb-[35px] mq1325:box-border"
        data-scroll-to="workContainer"
        data-animate-on-scroll
      >
        <div className="w-[1142px] flex flex-row items-start justify-center max-w-full">
          <h2 className="m-0 relative text-inherit font-semibold font-inherit mq800:text-13xl mq450:text-5xl">
            <span>{`My `}</span>
            <span className="text-darkorange">Work History</span>
          </h2>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[49px] max-w-full text-6xl mq800:gap-[24px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[53px] max-w-full mq800:gap-[26px]">
            <div className="w-[481px] h-[254px] rounded-mini box-border flex flex-row items-start justify-start py-[61px] px-9 max-w-full border-[5px] border-solid border-darkorange">
              <div className="h-[254px] w-[481px] relative rounded-mini box-border hidden max-w-full border-[5px] border-solid border-darkorange" />
              <div className="relative capitalize font-semibold z-[1] mq450:text-xl">
                <p className="m-0">Graphic Designer</p>
                <p className="m-0">Direct-to-Door · Freelance</p>
                <p className="m-0">Mar 2023 - Apr 2023 · 2 mos</p>
                <p className="m-0">United States · Remote</p>
              </div>
            </div>
            <div className="self-stretch flex-1 relative capitalize font-medium mq450:text-xl">
              I have work with Direct-To-Door Marketing by creating promotional
              content for their campaigns and by creating brand identity for
              their clients.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[53px] max-w-full mq800:gap-[26px]">
            <div className="w-[481px] h-[254px] rounded-mini box-border flex flex-row items-start justify-start py-[61px] px-9 max-w-full border-[5px] border-solid border-darkorange">
              <div className="h-[254px] w-[481px] relative rounded-mini box-border hidden max-w-full border-[5px] border-solid border-darkorange" />
              <div className="relative capitalize font-semibold z-[1] mq450:text-xl">
                <p className="m-0">Graphic Designer</p>
                <p className="m-0">Swan Media Productions Part-time</p>
                <p className="m-0">Feb 2022 - Jan 2023 · 1 yr</p>
                <p className="m-0">Islamabad · On-Site</p>
              </div>
            </div>
            <div className="self-stretch flex-1 relative capitalize font-medium whitespace-pre-wrap mq450:text-xl">
              I have also worked at Swan Media Productions as junior Graphic
              Designer and assisted them in many projects like Brand Identity,
              UI/UX and various designs for advertisement.
            </div>
          </div>
          <div className="flex flex-row items-start justify-start pt-0 px-0 pb-1 box-border max-w-full">
            <div className="rounded-mini box-border flex flex-row items-start justify-start py-[61px] px-9 max-w-full border-[5px] border-solid border-darkorange">
              <div className="h-[254px] w-[481px] relative rounded-mini box-border hidden max-w-full border-[5px] border-solid border-darkorange" />
              <div className="relative capitalize font-semibold z-[1] mq450:text-xl">
                <p className="m-0">React Developer</p>
                <p className="m-0">Octaloop Technologies · Internship</p>
                <p className="m-0">Jun 2020 - Jul 2020 · 2 mos</p>
                <p className="m-0">Islamabad, Pakistan · On-site</p>
              </div>
            </div>
          </div>
          <div className="self-stretch flex-1 relative capitalize mq450:text-xl">
            <p className="m-0 font-medium">
              I have also worked at Octaloop Technologies as a React.js and
              React native developer and assisted in many projects like website
              building and landing pages.
            </p>
            <p className="m-0 font-medium">&nbsp;</p>
            <p className="m-0">
              <b>My main highlights as a React developer are:</b>
            </p>
            <p className="m-0 font-medium">&nbsp;</p>
            <p className="m-0">
              <b className="font-exo-2">Building UI components:</b>
              <span className="font-medium">
                {" "}
                I designed and developed reusable components that make up the
                building blocks of their web app's interface.
              </span>
            </p>
            <p className="m-0">
              <b className="font-exo-2">Handling user interactions:</b>
              <span className="font-medium">
                {" "}
                I implement features that respond to user actions like clicks,
                form submissions, or mouse movements.
              </span>
            </p>
            <p className="m-0">
              <b className="font-exo-2">Ensuring responsiveness:</b>
              <span className="font-medium">
                {" "}
                I have ensured that the UI adapts seamlessly across different
                screen sizes and devices (desktops, mobiles, and tablets).
              </span>
            </p>
            <p className="m-0">
              <b className="font-exo-2">
                Collaborating with backend developers:
              </b>
              <span className="font-medium">
                {" "}
                I have worked with backend developers to integrate the UI with
                the application's data and functionality.
              </span>
            </p>
          </div>
          <ExperienceTab />
        </div>
      </div>
    </section>
  );
};

export default Work;
