import { useCallback, useEffect } from "react";

const Home = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onLinkedinContainerClick = useCallback(() => {
    window.open("https://www.linkedin.com/in/raja-waleed-abid-88088a217");
  }, []);

  const onGithubContainerClick = useCallback(() => {
    window.open("https://github.com/rajawaleed10");
  }, []);

  const onInstagramContainerClick = useCallback(() => {
    window.open("https://www.instagram.com/waleed.designs_/");
  }, []);

  const onDownloadButtonClick = useCallback(() => {
    window.open(
      "https://drive.google.com/file/d/1odqh3H7KXem_trv_ZB5doTJegMhQYXV2/view?usp=drive_link"
    );
  }, []);

  const onHireMeButtonClick = useCallback(() => {
    window.open("https://fiverr.com/rajasunny10");
  }, []);

  return (
    <section
      className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border [&.animate]:animate-[2s_ease_0s_1_normal_forwards_bounce-top] opacity-[1] max-w-full text-left text-11xl text-white font-exo-2"
      data-animate-on-scroll
    >
      <div className="flex-1 flex flex-row flex-wrap items-end justify-start pt-[107px] pb-[92px] pr-[73px] pl-[142px] box-border relative gap-[75px] max-w-full mq800:gap-[19px] mq800:pl-[35px] mq800:pt-[70px] mq800:pb-[60px] mq800:box-border mq450:pt-[45px] mq450:pb-[39px] mq450:box-border mq1325:gap-[37px] mq1325:pl-[71px] mq1325:pr-9 mq1325:box-border">
        <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] [backdrop-filter:blur(4px)] bg-midnightblue-100" />
        <div className="flex-1 flex flex-col items-start justify-start min-w-[372px] max-w-full mq800:min-w-full">
          <div className="flex flex-row items-start justify-start py-0 px-0.5 text-darkorange">
            <div className="relative font-semibold z-[1] mq800:text-5xl mq450:text-lg">
              Hi, there !
            </div>
          </div>
          <div className="w-[405px] flex flex-row items-start justify-start pt-0 px-0 pb-[19.8px] box-border max-w-full shrink-0 text-41xl">
            <h1 className="m-0 flex-1 relative text-inherit font-bold font-inherit inline-block max-w-full z-[1] mq800:text-29xl mq450:text-17xl">
              Waleed Abid
            </h1>
          </div>
          <div className="w-[540px] flex flex-row items-start justify-start pt-0 px-0.5 pb-5 box-border max-w-full shrink-0 text-16xl">
            <h3 className="m-0 flex-1 relative text-inherit font-semibold font-inherit inline-block max-w-full z-[1] mq800:text-9xl mq450:text-2xl">
              <span>{`And I'm a `}</span>
              <span className="text-darkorange">{`Frontend Developer & Graphic Designer`}</span>
            </h3>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[37px] box-border max-w-full shrink-0 text-base font-inter">
            <div className="flex-1 relative leading-[24px] inline-block max-w-full z-[1]">
              I'm a Graphic Designer And a Front-End Developer with a focus on
              creating exceptional digital experiences that are fast,
              accessible, visually appealing, and responsive. Even though I have
              been creating web applications for over 2 years, I still love it
              as if it was something new.
            </div>
          </div>
          <div className="w-[146px] flex flex-row items-start justify-start pt-0 px-1 pb-[38.5px] box-border">
            <div className="flex-1 flex flex-row items-start justify-between gap-[20px]">
              <div className="flex flex-col items-start justify-start pt-px px-0 pb-0">
                <div
                  className="w-[30px] h-[30px] relative cursor-pointer z-[1]"
                  onClick={onLinkedinContainerClick}
                >
                  <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkorange w-full h-full" />
                  <img
                    className="absolute h-[46%] top-[28.33%] bottom-[25.67%] left-[calc(50%_-_7px)] max-h-full w-[13.2px] z-[1]"
                    loading="lazy"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
              </div>
              <div
                className="h-[30px] w-[30px] relative cursor-pointer z-[1]"
                onClick={onGithubContainerClick}
              >
                <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkorange w-full h-full" />
                <img
                  className="absolute h-[49.67%] top-[28.33%] bottom-[22%] left-[calc(50%_-_7px)] max-h-full w-[14.6px] z-[1]"
                  loading="lazy"
                  alt=""
                  src="/vector-1.svg"
                />
              </div>
              <div
                className="h-[30px] w-[30px] relative cursor-pointer z-[1]"
                onClick={onInstagramContainerClick}
              >
                <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-darkorange w-full h-full" />
                <img
                  className="absolute h-[46%] w-[44%] top-[28.33%] right-[29.33%] bottom-[25.67%] left-[26.67%] max-w-full overflow-hidden max-h-full z-[1]"
                  loading="lazy"
                  alt=""
                  src="/vector-2.svg"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start pt-0 px-1 pb-[20.1px]">
            <button
              className="cursor-pointer [border:none] pt-2.5 px-11 pb-[9px] bg-darkorange rounded-xl flex flex-row items-start justify-start whitespace-nowrap z-[1] hover:bg-chocolate"
              onClick={onDownloadButtonClick}
            >
              <div className="h-[40.9px] w-[200px] relative rounded-xl bg-darkorange hidden" />
              <div className="relative text-lg font-semibold font-exo-2 text-white text-left inline-block min-w-[111px] z-[1]">
                Download CV
              </div>
            </button>
          </div>
          <div className="flex flex-row items-start justify-start py-0 px-1">
            <button
              className="cursor-pointer [border:none] pt-[9.8px] pb-[8.2px] pr-[66px] pl-[69px] bg-white rounded-xl flex flex-row items-start justify-start whitespace-nowrap z-[1] hover:bg-gainsboro"
              onClick={onHireMeButtonClick}
            >
              <div className="h-10 w-[200px] relative rounded-xl bg-white hidden" />
              <div className="relative text-lg font-semibold font-exo-2 text-darkorange text-left inline-block min-w-[65px] z-[1]">
                Hire Me
              </div>
            </button>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-4 box-border min-w-[375px] max-w-full mq800:min-w-full">
          <img
            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[1] hover:animate-[1s_ease_0s_infinite_normal_none_pulsate] hover:opacity-[1]"
            loading="lazy"
            alt=""
            src="/banner-image@2x.png"
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
