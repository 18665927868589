const ExperienceTab = () => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[53px] max-w-full text-left text-6xl text-white font-exo-2 mq800:gap-[26px]">
      <div className="w-[481px] rounded-mini box-border flex flex-row items-start justify-start py-[61px] px-9 max-w-full border-[5px] border-solid border-darkorange">
        <div className="h-[254px] w-[481px] relative rounded-mini box-border hidden max-w-full border-[5px] border-solid border-darkorange" />
        <div className="relative capitalize font-semibold z-[1] mq450:text-xl">
          <p className="m-0">Next JS Developer</p>
          <p className="m-0">Hendry Group · Seasonal</p>
          <p className="m-0">Feb 2023 - Present · 1 yr 3 mos</p>
          <p className="m-0">Australia · Remote</p>
        </div>
      </div>
      <div className="self-stretch flex-1 relative capitalize font-medium mq450:text-xl">
        Made web applications using Next.js, React, and related technologies
        (JavaScript, HTML, CSS). Designed and implemented reusable UI components
        to ensure a consistent user experience. Optimize applications for
        performance and scalability to handle growing user traffic.
      </div>
    </div>
  );
};

export default ExperienceTab;
